import { reducer } from './reducer';
import { AppSidebarContextTypes } from '../../lib/enums/AppSidebarContextTypes';
import { AppTab } from '../../lib/interfaces/AppTab/IAppTab';
import { FormSchemaCheckboxOption } from '../../components/Entity/DefaultEntityTable';
import { SideMenuSchemaCheckboxOption } from '../../components/Application/Sidebars/ColumnSelectSidebar';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface AppState {
  appSidebarOpenContext?: AppSidebarContextTypes;
  columnSelectSidebarOpenContext?: AppSidebarContextTypes;

  selectableTableColumns? : SideMenuSchemaCheckboxOption[];
  uiTheme: string;
  tabs: AppTab[];
  currentTabId: string;
  currentTabIndex: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface SetAppSidebarContextAction {
  type: "SET_APP_SIDEBAR_CONTEXT";
  context: AppSidebarContextTypes | undefined;


}



interface SetColumnSelectSidebarOpenAction {
  type: "SET_COLUMN_SELECT_SIDEBAR_OPEN";
  columnSelectSidebarOpenContextPassedIn: AppSidebarContextTypes | undefined;
}

interface SetColumnSelectSidebarContextAction {
  type: "SET_COLUMN_SELECT_SIDEBAR_CONTEXT";
  columnSelectSidebarCheckboxesPassedIn : SideMenuSchemaCheckboxOption[] | undefined;
}

interface SetUiThemeAction {
  type: "SET_UI_THEME";
  theme: string;
}

interface AddNewTab {
  type: "ADD_NEW_TAB";
  username: string;
  tabToAdd: AppTab;
}


interface InitialiseTabsFromLocalStorage {
  type: "INITIALISE_TABS_FROM_LS";
  username: string;
}


interface CloseTab {
  type: "CLOSE_TAB";
  username: string;
  idOfTabToClose: string;
  currentlySelectedTabId: string;
}

interface SetCurrentlySelectedTabId {
  type: "SET_CURRENT_TAB_ID";
  username: string;
  currentlySelectedId: string;
  currentlySelectedIndex: number;
}

interface ClearTabState {
  type: "CLEAR_TAB_STATE"
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAppActions = SetAppSidebarContextAction | SetUiThemeAction | AddNewTab | InitialiseTabsFromLocalStorage |SetCurrentlySelectedTabId | CloseTab | ClearTabState | SetColumnSelectSidebarOpenAction | SetColumnSelectSidebarContextAction;

export const unloadedAppState: AppState = {
  appSidebarOpenContext: undefined,
  columnSelectSidebarOpenContext: undefined,
  selectableTableColumns: undefined,
  uiTheme: 'dark',
  tabs: [],
  currentTabId: '',
  currentTabIndex: -1
};

export { reducer };
