import React from 'react';

const StockEntry = React.lazy(() => import('../../views/StockControl/StockEntry/StockEntry'));
const StockEnquiry = React.lazy(() => import('../../views/StockControl/StockEnquiry/StockEnquiry'));
const PieceAudit = React.lazy(() => import('../../views/StockControl/PieceAudit/PieceAudit'));
const StockValuation = React.lazy(() => import('../../views/StockControl/StockValuation/StockValuation'));

const StockReservation = React.lazy(() => import('../../views/StockControl/StockReservation/StockReservation'))

const salesOrdersRoutesProtected = [
    { path: '/stock/reports/stock-entry', exact: true, name: 'Stock Entry', component: StockEntry }
    ,{ path: '/stock/reports/stock-enquiry', exact: true, name: 'Stock Enquiry', component: StockEnquiry }
    ,{ path: '/stock/reports/piece-audit', exact: true, name: 'Piece Audit', component: PieceAudit }
    ,{ path: '/stock/reports/stock-valuation', exact: true, name: 'Stock Valuation', component: StockValuation}
    ,{ path: '/stock/stock-reservation', exact: true, name: 'Stock Reservation', component: StockReservation}
];

export default salesOrdersRoutesProtected;