import React from 'react';

const SystemKeys = React.lazy(() => import('../../views/Maintenance/SystemKeys/SystemKeys'));
const Users = React.lazy(() => import('../../views/Maintenance/Users/Users'));
const Roles = React.lazy(() => import('../../views/Maintenance/Roles/Roles'));
const Menus = React.lazy(() => import('../../views/Maintenance/Menus/Menus'));
const Grades = React.lazy(() => import('../../views/Maintenance/Grades/Grades'));
const Calendars = React.lazy(() => import('../../views/Maintenance/Calendar/Calendar'));
const AnalysisCodes = React.lazy(() => import('../../views/Maintenance/AnalysisCodes/AnalysisCodes'));
const DeliveryMaintenance = React.lazy(() => import('../../views/Maintenance/Deliveries/DeliveryMethodMaintenance'));
const DivisionMaintenance = React.lazy(() => import('../../views/Maintenance/Divisions/DivisionMaintenance'));
const WarehouseMaintenance = React.lazy(() => import('../../views/Maintenance/Warehouses/WarehouseMaintenance'));
const LocationMaintenance = React.lazy(() => import('../../views/Maintenance/Locations/LocationMaintenance'));
const LocationTypes = React.lazy(() => import('../../views/Maintenance/Locations/LocationTypeMaintenance'));
const Localization = React.lazy(() => import('../../views/Maintenance/Localization/Localization'));
const SalesRepMaintenance = React.lazy(() => import('../../views/Maintenance/SalesReps/SalesRepMaintenance'));

const maintenanceRoutesProtected = [
  { path: '/maintenance/system-keys', exact: true, name: 'System Keys', component: SystemKeys },
  { path: '/maintenance/users', exact: true, name: 'Users', component: Users },
  { path: '/maintenance/roles', exact: true, name: 'Roles', component: Roles },
  { path: '/maintenance/menus', exact: true, name: 'Menus', component: Menus },
  { path: '/maintenance/grades', exact: true, name: 'Grades', component: Grades },
  { path: '/maintenance/calendars', exact: true, name: 'Calendars', component: Calendars},
  { path: '/maintenance/analysiscodes', exact: true, name: 'Analysis Codes', component: AnalysisCodes},
  { path: '/maintenance/deliverymethods', exact: true, name: 'Delivery Methods', component: DeliveryMaintenance },
  { path: '/maintenance/divisionmaintenance', exact: true, name: 'Division Maintenance', component: DivisionMaintenance },
  { path: '/maintenance/warehousemaintenance', exact: true, name: 'Warehouse Maintenance', component: WarehouseMaintenance },
  { path: '/maintenance/locationmaintenance', exact: true, name: 'Location Maintenance', component: LocationMaintenance },
  { path: '/maintenance/locationtypemaintenance', exact: true, name: 'Location Type Maintenance', component: LocationTypes },
  { path: '/maintenance/localization', exact: true, name: 'Localization', component: Localization },
  { path: '/maintenance/salesrepmaintenance', exact: true, name: 'Sales Rep Maintenance', component: SalesRepMaintenance}
];

export default maintenanceRoutesProtected;
