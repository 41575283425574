import React from 'react';


const SalesOrderEntry = React.lazy(() => import('../../views/SalesOrders/SalesOrderEntry/SalesOrderEntry'));
const SalesOrderEnquiry = React.lazy(() => import('../../views/SalesOrders/SalesOrderEnquiry/SalesOrderEnquiry'));
const RouteMaintenance = React.lazy(() => import('../../views/Maintenance/RouteMaintenance/RouteMaintenance'))
const CreditControl = React.lazy(() => import('../../views/SalesOrders/CreditControl/CreditControl'))
const salesOrdersRoutesProtected = [
    { path: '/salesorders/sales-order-entry', exact: true, name: 'Sales Order Entry', component: SalesOrderEntry },
    { path: '/salesorders/sales-order-enquiry', exact: true, name: 'Sales Order Enquiry', component: SalesOrderEnquiry },
    { path: '/salesorders/route-maintenance', exact: true, name: 'Route Maintenance', component: RouteMaintenance},
    { path: '/salesorders/credit-control', exact: true, name: 'Credit Control', component: CreditControl},
];

export default salesOrdersRoutesProtected;