import React from 'react';
import salesOrdersRoutesProtected from './RoutesProtected/SalesOrders';
import stockControlRoutesProtected from './RoutesProtected/StockControl';
import maintenanceRoutesProtected from './RoutesProtected/Maintenance';
//import companyRoutesProtected from './RoutesProtected/Company';
import desktopRoutesProtected from './RoutesProtected/Desktop';

const Dashboard = React.lazy(() => import('../views/Dashboard/Dashboard'));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routesProtected = [
  // { path: '/products/:stockRef?', exact: true, name: 'Products', component: Products },
  { path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
  ...salesOrdersRoutesProtected,
  ...maintenanceRoutesProtected,
  ...stockControlRoutesProtected,
//  ...companyRoutesProtected,
  ...desktopRoutesProtected,
  { path: '/', exact: true, name: 'Dashboard', component: Dashboard }
];

export default routesProtected;
